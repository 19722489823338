const axios = require('axios');

export default {
    name: 'search',
    data() {
        return {
            estate: null,
            current: null,
            info: true
        }
    },
    methods: {
        get: async function() {
            try {
                let response = await axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_API_URL}/api/public/get/estate/${this.$route.params.id}`,
                    headers: { source: process.env.VUE_APP_PUBLIC_KEY }
                });
                this.estate = response.data;
                if(this.estate.images.length > 0) this.current = this.estate.images[0];
            } catch (error) {
                this.estate = false;
            }
        }
    },
    async mounted() {
      await this.get();
    }
}
